


<template>
    <div>
        <mdb-navbar v-show="navbarType == 'regular-fixed'" position="top"  dark color="indigo" name="Your Logo" href="#" scrolling>
            <a href="https://agustinostv-front.archive.tornadocore.es" style="color: white;padding-right: 30px"><b>AGUSTINOS.TV</b></a>

        </mdb-navbar>

        <div style=" margin-bottom: -25px;margin-top:-55px;">
            <div class="view intro-2">
                <div class="full-bg-img" style="height:30% !important;">
                    <div class="mask rgba-black-strong" style="padding-top: 24%;padding-bottom: 24%;padding-left: 60px;padding-right: 60px;">






                    </div>
                </div>
            </div>
        </div>
        <mdb-footer  dark color="indigo">
            <p class="footer-copyright mb-0 py-3 text-center">
                &copy; {{new Date().getFullYear()}} Copyright:
                <a
                        href="#"
                >AGUSTINOS.TV</a>
                | Versión 3.20.01.01
            </p>
        </mdb-footer>

        <mdb-modal :show="showModalRecuperar" @close="showModalRecuperar = false" cascade class="text-left">
            <mdb-modal-header class="primary-color white-text">
                <h4 class="title"><mdb-icon icon="pencil-alt" /> Recuperar Cuenta</h4>
            </mdb-modal-header>
            <mdb-modal-body class="grey-text">
                <mdb-input size="sm" label="Introduce tu nueva contraseña" icon="envelope" v-model="password" group type="password" validate error="wrong" success="right"/>
                <mdb-input size="sm" label="Repite tu nueva contraseña" icon="envelope" v-model="password2" group type="password" validate error="wrong" success="right"/>
            </mdb-modal-body>
            <mdb-modal-footer>
                <b-row style="width:100%">
                    <div class="col-md-6">
                    </div>
                    <div class="col-md-6">
                        <mdb-btn color="primary" @click="Recuperar">Recuperar</mdb-btn>
                    </div>


                </b-row>
            </mdb-modal-footer>
        </mdb-modal>


    </div>
</template>






<script>

    import {
        mdbNavbar,
        mdbNavItem,
        mdbNavbarNav,
        mdbNavbarToggler,
        mdbNavbarBrand,
        mdbFooter,
        mdbTooltip,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle,
        mdbInput,
        mdbContainer,
        mdbRow,
        mdbCol,
        mdbModal,
        mdbModalHeader,
        mdbModalBody,
        mdbModalFooter,
        mdbBtn,
        mdbIcon,
    } from "mdbvue"
    import ApiService from '@/services/api';

    export default {
        name: 'Login',
        components: {
            mdbNavbar,
            mdbNavItem,
            mdbNavbarNav,
            mdbNavbarToggler,
            mdbModal,
            mdbModalHeader,
            mdbModalBody,
            mdbModalFooter,
            mdbNavbarBrand,
            mdbFooter,
            mdbTooltip,
            mdbDropdown,
            mdbDropdownItem,
            mdbDropdownMenu,
            mdbDropdownToggle,
            mdbInput,
            mdbContainer,
            mdbRow,
            mdbCol,
            mdbBtn,
            mdbIcon,
        },
        data() {
            return {
                navbarType: 'regular-fixed',
                showModalRecuperar: true,
                email: '',
                password: null,
                password2: null,
                token: null,
            }
        },
        mounted() {
            if(this.$route.query.token)
            {
                this.token = this.$route.query.token;
            }


        },
        created() {
            window.document.title = "AGUSTINOS.TV";
        },
        methods: {
            Recuperar(){

                if(this.password != null && this.password2 != null && this.password.length > 0 && this.password2.length > 0 && this.password == this.password2) {

                    console.log('haciendo login ...');
                    ApiService.RecuperarContrasenya(this.token, this.password).then(response => {
                        console.log(response);
                        var that = this;
                        if (response.status == 200) {
                            this.$swal.fire({
                                title: 'Cuenta Recuperada',
                                text: 'Ya puedes conectarte de nuevo a Agustinos.TV',
                                type: 'success',
                                showCancelButton: false,
                            }).then(confirm => {
                                that.$router.push({path: '/'});
                            });

                        }

                    }).catch(error => {
                        if (error.toString().includes('409')) {
                            this.$swal.fire({
                                title: 'Faltan Datos !',
                                text: '',
                                type: 'error',
                                showCancelButton: false,
                            });

                        } else if (error.toString().includes('404')) {
                            this.$swal.fire({
                                title: 'El token no es válido o ha caducado',
                                text: '',
                                type: 'error',
                                showCancelButton: false,
                            });

                        } else if (error.toString().includes('401')) {
                            this.$swal.fire({
                                title: 'Usuarios no encontrado',
                                text: '',
                                type: 'error',
                                showCancelButton: false,
                            });

                        } else {
                            this.$swal.fire({
                                title: 'Error',
                                text: '',
                                type: 'error',
                                showCancelButton: false,
                            });
                        }
                    });

                }
                else
                {
                    this.$swal.fire({
                        title: 'Error de datos',
                        text: 'Introduce tu contraseña 2 veces y escribela exactamente igual',
                        type: 'error',
                        showCancelButton: false,
                    });

                }


            },
            PedirActivacion(){

                console.log('haciendo login ...');
                ApiService.PedirActivacion(this.email).then(response => {
                    console.log(response);
                    if (response.status == 200) {
                        this.$swal.fire({
                            title: 'Petición enviada',
                            text: 'Revisa tu correo y sigue las instrucciones',
                            type: 'success',
                            showCancelButton: false,
                        });

                    }

                }).catch(error => {
                    if(error.toString().includes('409'))
                    {
                        this.$swal.fire({
                            title: 'Tu cuenta ya está activada!',
                            text: 'No necesitas activar tu cuenta, inicia sesión normalmente o recupera tu contraseña si no la recuerdas',
                            type: 'info',
                            showCancelButton: false,
                        });

                    }
                    else
                    {
                        this.$swal.fire({
                            title: 'Usuarios no encontrado',
                            text: 'El email facilitado no parece pertenecer a ninguna cuenta registrada. Registrate y empieza a usar Agustinos.TV',
                            type: 'error',
                            showCancelButton: false,
                        });
                    }
                });


            },


        }
    }


</script>

<style>
    @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

    .flyout {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        justify-content: space-between;
    }
    .active {
        background-color: rgba(255, 255, 255, 0.1);
    }
    .demo-section {
        padding: 20px 0;
    }
    .demo-section > section {
        border: 1px solid #e0e0e0;
        padding: 15px;
    }
    .demo-section > h4 {
        font-weight: bold;
        margin-bottom: 20px;
    }
    .demo-title {
        color: #9e9e9e;
        font-weight: 700;
        margin-bottom: 0;
        padding-left: 15px;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.3s;
        transition-property: opacity;
        transition-timing-function: ease-out;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0;
    }

    .view{
        background: url("https://mdbootstrap.com/img/Photos/Others/img (42).jpg") no-repeat center center;
        background-size: cover;
        height: 100vh;
    }
</style>
